import * as React from 'react';
import { AuctionStatus } from '@/types/LiveAuctionState';
import { Caption, H5 } from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import { FormattedMessage, FormattedNumber } from '@liveauctioneers/hammer-ui-core/intl';
import { getItemModelUrl, getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import { getLiveItemStatusOpen } from '@/redux/modules/liveItemStatus';
import { useAppSelector } from '@/redux/hooks';
import { useNavigate } from 'react-router-dom';
import FormattedCurrency from '@/components/Formatted/FormattedCurrency';
import ItemPriceGatewayContainer from '@/components/ItemPriceGateway/ItemPriceGatewayContainer';
import noop from '@/utils/noop';
import styled, { css } from 'styled-components';

export type PriceRowProps = {
    className?: string;
    fullWidth?: boolean;
    isCatalogCard?: boolean;
    itemId: number;
    onNavigate?: () => void;
    seeSoldPriceHover?: (isHovered: boolean) => void;
};

const PriceRow = ({
    className,
    fullWidth = true,
    isCatalogCard,
    itemId,
    onNavigate,
    seeSoldPriceHover = noop,
}: PriceRowProps) => {
    const navigate = useNavigate();
    const isLotOpen = useAppSelector((state) => getLiveItemStatusOpen(state, itemId));
    const itemUrl = useAppSelector((state) => getItemModelUrl(state, itemId));

    const {
        bidActivityCount,
        bidCount,
        buyNowPrice,
        buyNowStatus,
        catalogStatus,
        currency = 'USD',
        isAvailable,
        isCatalogOnly,
        isLocked,
        isPassed,
        isPaused,
        isSold,
        leadingBid,
        salePrice,
        startPrice,
    } = useAppSelector((state) => getItemSummary(state, itemId));
    const numberOfBids = Math.max(bidCount, bidActivityCount);

    const isDone = catalogStatus === AuctionStatus.Done;
    const buyNowAvailable = buyNowStatus === 1 && isDone;
    const isUnsold = isLocked && !isPassed && !isSold && !isLotOpen;
    const currentPrice = leadingBid || salePrice || startPrice || 0;

    let primaryReturnComponent: React.ReactNode = null;

    if (!isDone && isPaused) {
        primaryReturnComponent = (
            <StyledItemPriceGatewayContainer
                hideIcon={isCatalogCard}
                itemId={itemId}
                showChildren
            >
                <H5>
                    <FormattedMessage id="lotWithdrawn" />
                </H5>
            </StyledItemPriceGatewayContainer>
        );
    } else if (isLotOpen) {
        primaryReturnComponent = (
            <LiveItemCurrentBidContainerDiv>
                <ItemLiveBidDiv>
                    <H5>
                        <BidAmountText>
                            <FormattedCurrency
                                convert
                                noFraction
                                sourceCurrency={currency}
                                value={currentPrice}
                            />
                        </BidAmountText>
                    </H5>
                    <BidCountText>
                        <Caption $darkGrey>
                            <FormattedMessage
                                id="bidCount"
                                values={{
                                    formattedBid: (
                                        <FormattedNumber
                                            key="formatted_bid"
                                            value={numberOfBids}
                                        />
                                    ),
                                    numberOfBids: numberOfBids,
                                }}
                            />
                        </Caption>
                    </BidCountText>
                </ItemLiveBidDiv>
            </LiveItemCurrentBidContainerDiv>
        );
    } else if (buyNowAvailable) {
        primaryReturnComponent = (
            <CurrentPriceText>
                <StyledPriceRowSpan>
                    <H5>
                        <FormattedMessage
                            id="buy_now_for"
                            values={{
                                price: (
                                    <FormattedCurrency
                                        key="buy-now-for-price"
                                        sourceCurrency={currency}
                                        value={buyNowPrice}
                                    />
                                ),
                            }}
                        />
                    </H5>
                </StyledPriceRowSpan>
            </CurrentPriceText>
        );
    } else if (isAvailable && !isUnsold && !isCatalogOnly) {
        primaryReturnComponent = (
            <StyledPriceRowSpan>
                <H5>
                    <FormattedCurrency
                        convert
                        noFraction
                        sourceCurrency={currency}
                        value={currentPrice}
                    />
                </H5>
                <StyledBidAmount>
                    {!Boolean(bidActivityCount) ? null : (
                        <FormattedMessage
                            id="bidCount"
                            values={{
                                formattedBid: (
                                    <FormattedNumber
                                        key="formatted_bid"
                                        value={bidActivityCount}
                                    />
                                ),
                                numberOfBids: bidActivityCount,
                            }}
                        />
                    )}
                </StyledBidAmount>
            </StyledPriceRowSpan>
        );
    } else {
        let returnComponentChild: React.ReactNode = null;

        if (!Boolean(salePrice) && isSold) {
            returnComponentChild = (
                <StyledItemPriceGatewayContainer
                    hideIcon={isCatalogCard}
                    itemId={itemId}
                    runFunction={() => {
                        onNavigate?.();
                        navigate({ pathname: itemUrl });
                    }}
                >
                    <H5>
                        <FormattedMessage id="sold" />
                    </H5>
                </StyledItemPriceGatewayContainer>
            );
        } else if (Boolean(salePrice) && isSold) {
            returnComponentChild = (
                <div
                    onMouseOut={() => seeSoldPriceHover(false)}
                    onMouseOver={() => seeSoldPriceHover(true)}
                >
                    <StyledItemPriceGatewayContainer
                        hideIcon={isCatalogCard}
                        itemId={itemId}
                        runFunction={() => {
                            onNavigate?.();
                            navigate({ pathname: itemUrl });
                        }}
                    >
                        <H5>
                            <FormattedMessage
                                id="sold_for"
                                values={{
                                    amount: (
                                        <FormattedCurrency
                                            key="sold-for-amount"
                                            noFraction
                                            sourceCurrency={currency}
                                            value={salePrice}
                                        />
                                    ),
                                }}
                            />
                        </H5>
                    </StyledItemPriceGatewayContainer>
                </div>
            );
        } else if (isDone && isPaused) {
            returnComponentChild = (
                <StyledItemPriceGatewayContainer
                    hideIcon={isCatalogCard}
                    itemId={itemId}
                >
                    {isCatalogOnly ? (
                        <H5>
                            <FormattedMessage id="lot_closed" />
                        </H5>
                    ) : null}
                </StyledItemPriceGatewayContainer>
            );
        } else if (isUnsold) {
            returnComponentChild = (
                <StyledItemPriceGatewayContainer
                    hideIcon={isCatalogCard}
                    itemId={itemId}
                >
                    <H5>
                        <FormattedMessage id="lot_unsold_pending" />
                    </H5>
                </StyledItemPriceGatewayContainer>
            );
        } else if (isPassed) {
            returnComponentChild = (
                <StyledItemPriceGatewayContainer
                    hideIcon={isCatalogCard}
                    itemId={itemId}
                    showChildren
                >
                    <H5>
                        {isCatalogOnly ? (
                            <FormattedMessage id="lot_closed" />
                        ) : (
                            <FormattedMessage id="itemBidding.passedText" />
                        )}
                    </H5>
                </StyledItemPriceGatewayContainer>
            );
        }

        primaryReturnComponent = <StyledPriceRowSpan>{returnComponentChild}</StyledPriceRowSpan>;
    }

    return (
        <WrapperText
            $useFullWidthStyle={fullWidth}
            className={className}
        >
            {primaryReturnComponent}
        </WrapperText>
    );
};

export default React.memo<PriceRowProps>(PriceRow);

const WrapperFullWidthStyle = css`
    &&& {
        width: 100%;
    }
`;

const WrapperText = styled(H5)<{ $useFullWidthStyle: boolean }>`
    ${(props) => props.$useFullWidthStyle && WrapperFullWidthStyle}
    display: flex;
    margin-top: 4px;
    @media (max-width: ${({ theme }) => theme.breakpoints.mobileWidth}) {
        margin-top: unset;
    }
`;

WrapperText.displayName = 'WrapperText';

const LiveItemCurrentBidContainerDiv = styled.div`
    display: flex;
    flex-direction: column;

    > * {
        flex: 1 1 50%;
    }
`;

const StyledPriceRowSpan = styled.span`
    color: ${({ theme }) => theme.colors.black};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 4px;
`;

const ItemLiveBidDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

const BidAmountText = styled.span`
    color: ${({ theme }) => theme.colors.black};
    margin-right: 8px;
`;

const BidCountText = styled.span`
    margin: -2px 0 0 -5px;
`;

// cant have an h5 as a child of h6
const CurrentPriceText = styled.span`
    line-height: 22px;
`;

const StyledItemPriceGatewayContainer = styled(ItemPriceGatewayContainer)`
    height: 100%;
    letter-spacing: 0.5px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidthNum + 1}px) {
        font-size: 14px;
    }
`;

const StyledBidAmount = styled(Caption)`
    color: ${({ theme }) => theme.colors.grey100};
`;
