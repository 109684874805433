import * as React from 'react';
import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getItemModelCurrency,
    getItemModelHighBidEstimate,
    getItemModelLowBidEstimate,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { useAppSelector } from '@/redux/hooks';
import FormattedCurrency from '@/components/Formatted/FormattedCurrency';

type Props = {
    className?: string;
    itemId: number;
};

const ItemCardEstimate = ({ className, itemId }: Props) => {
    const currency = useAppSelector((state) => getItemModelCurrency(state, itemId));
    const highBidEstimate = useAppSelector((state) => getItemModelHighBidEstimate(state, itemId));
    const lowBidEstimate = useAppSelector((state) => getItemModelLowBidEstimate(state, itemId));

    if (!lowBidEstimate && !highBidEstimate) {
        return (
            <BodySecondary
                aria-label="price range estimate"
                className={className}
                color="secondary"
            >
                <FormattedMessage
                    id="short_estimate_value"
                    key="short_estimate_value"
                    values={{ number: '—' }}
                />
            </BodySecondary>
        );
    }

    if (!lowBidEstimate || !highBidEstimate) {
        return (
            <BodySecondary
                aria-label="price range estimate"
                className={className}
                color="secondary"
            >
                <FormattedMessage
                    id="short_estimate_value"
                    values={{
                        number: (
                            <FormattedCurrency
                                key="short_estimate_value"
                                noFraction
                                sourceCurrency={currency}
                                value={lowBidEstimate || highBidEstimate}
                            />
                        ),
                    }}
                />
            </BodySecondary>
        );
    }

    return (
        <BodySecondary
            aria-label="price range estimate"
            className={className}
            color="secondary"
        >
            <FormattedMessage
                id="short_estimate_values"
                values={{
                    high: (
                        <FormattedCurrency
                            key="high_estimate_value"
                            noFraction
                            sourceCurrency={currency}
                            value={highBidEstimate}
                        />
                    ),
                    low: (
                        <FormattedCurrency
                            key="low_estimate_value"
                            noFraction
                            sourceCurrency={currency}
                            value={lowBidEstimate}
                        />
                    ),
                }}
            />
        </BodySecondary>
    );
};

export default ItemCardEstimate;
