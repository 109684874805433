import { isCategoryPage, isPriceGuidePage, isSearchPage } from '@/utils/urls';
import { useLocation } from 'react-router-dom';

export const useIsOnCategorySearchOrPriceGuidePage = () => {
    const location = useLocation();
    const isOnCategoryPage = isCategoryPage(location.pathname);
    const isOnSearchPage = isSearchPage(location.pathname);
    const isOnPriceGuidePage = isPriceGuidePage(location.pathname);
    return isOnCategoryPage || isOnSearchPage || isOnPriceGuidePage;
};
