import ms from 'ms';

interface CheckReadyToBidParams {
    catalogId: number;
    closed: boolean;
    currentAskPrice: number;
    currentItem: number;
    itemId: number;
    liveUpdateTimestamp: number;
    networkStatus: string;
    status: string;
    won: boolean;
}

export const checkReadyToBid = ({
    catalogId,
    closed,
    currentAskPrice,
    currentItem,
    itemId,
    liveUpdateTimestamp,
    networkStatus,
    status,
    won,
}: CheckReadyToBidParams) => {
    const timeSinceLastMessage = Date.now() - liveUpdateTimestamp;

    return (
        itemId === currentItem &&
        itemId > 0 &&
        networkStatus === 'connected' &&
        status === 'live' &&
        !closed &&
        !won &&
        Boolean(currentAskPrice) &&
        catalogId > 0 &&
        timeSinceLastMessage < ms('5m')
    );
};
