import { getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import { useAppSelector } from '@/redux/hooks';
import { useEffect, useRef, useState } from 'react';

type Props = {
    itemId: number;
};

export const useGetTimedPlusCountdown = ({ itemId }: Props) => {
    const { isPassed, isSold, lotEndTimeEstimatedTs, saleStartTs } = useAppSelector((state) =>
        getItemSummary(state, itemId)
    );

    // This is added to update the component every second and minute
    let intervalRef = useRef<ReturnType<typeof setTimeout>>(null);
    const [today, setToday] = useState(Date.now());

    const saleStart = saleStartTs * 1000;
    const saleEnd = lotEndTimeEstimatedTs * 1000;

    const differenceFromEndTs = saleEnd - today;
    const totalDaysFromEnd = Math.floor(differenceFromEndTs / (1000 * 3600 * 24));

    const hoursUntilEnd = Math.floor((Math.round(saleEnd - today) / (1000 * 60 * 60)) % 24);
    const minutesUntilEnd = Math.floor((Math.round(saleEnd - today) / (1000 * 60)) % 60);
    const secondsUntilEnd = Math.floor((Math.round(saleEnd - today) / 1000) % 60);

    const saleStartIsPassed = saleStart < today;

    useEffect(() => {
        if (totalDaysFromEnd === 0 && saleStartIsPassed && saleEnd > Date.now() && !isSold && !isPassed) {
            if (hoursUntilEnd > 0) {
                intervalRef.current = setInterval(() => {
                    setToday(Date.now());
                }, 60000);
            }
            if (hoursUntilEnd === 0) {
                intervalRef.current = setInterval(() => {
                    setToday(Date.now());
                }, 1000);
            }
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [saleStartIsPassed, hoursUntilEnd, totalDaysFromEnd, saleEnd, isSold, isPassed]);

    return {
        hoursUntilEnd,
        minutesUntilEnd,
        saleEnd,
        saleStartIsPassed,
        secondsUntilEnd,
        today,
        totalDaysFromEnd,
    };
};
