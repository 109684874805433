import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import { RedTextButtonRight } from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import { SubtleLink } from '@liveauctioneers/hammer-ui-core/link';
import { useAppSelector } from '@/redux/hooks';
import { useGetTimedPlusCountdown } from './hooks/useGetTimedPlusCountdown';
import { useRefetchItemSummariesIfNeeded } from './hooks/useRefetchItemSummariesIfNeeded';
import CatalogDate from '@/components/CatalogDate/CatalogDate';
import styled from 'styled-components';

export type ItemCardCountdownProps = {
    className?: string;
    itemId: number;
    onNavigate?: () => void;
};

export default function ItemCardCountdown({ className, itemId, onNavigate }: ItemCardCountdownProps) {
    const { isPassed, isPaused, isSold, lotEndTimeEstimatedTs, saleStartTs } = useAppSelector((state) =>
        getItemSummary(state, itemId)
    );
    const { hoursUntilEnd, minutesUntilEnd, saleEnd, saleStartIsPassed, secondsUntilEnd, today, totalDaysFromEnd } =
        useGetTimedPlusCountdown({ itemId });

    useRefetchItemSummariesIfNeeded({ itemId, saleEnd, today });

    if (isPaused) {
        return null;
    }

    if (isSold || isPassed || saleEnd < today) {
        return (
            <BodySecondary className={className}>
                <CatalogDate
                    date={lotEndTimeEstimatedTs}
                    showOnlyYear
                />
            </BodySecondary>
        );
    }

    // IF AFTER SALE START AND NOT SOLD
    if (saleStartIsPassed) {
        // MORE THAN A DAY AWAY OR ONE DAY AWAY FROM SALE END
        if (totalDaysFromEnd >= 1) {
            return (
                <StyledLink
                    href={`/item/${itemId}`}
                    onClick={onNavigate}
                >
                    <RedTextButtonRight
                        className={cn('flex items-center', className)}
                        role="button"
                    >
                        <FormattedMessage
                            id="itemBidding.endsInDays"
                            values={{
                                days: totalDaysFromEnd,
                                hours: hoursUntilEnd,
                            }}
                        />
                    </RedTextButtonRight>
                </StyledLink>
            );
        }

        // LESS THAN A DAY AWAY FROM SALE END
        if (totalDaysFromEnd === 0 && hoursUntilEnd !== 0) {
            return (
                <StyledLink
                    href={`/item/${itemId}`}
                    onClick={onNavigate}
                >
                    <RedTextButtonRight
                        className={cn('flex items-center', className)}
                        role="button"
                    >
                        <FormattedMessage
                            id="itemBidding.endsInTime"
                            values={{
                                hours: hoursUntilEnd,
                                minutes: minutesUntilEnd,
                            }}
                        />
                    </RedTextButtonRight>
                </StyledLink>
            );
        }

        // LESS THAN ONE MINUTE AWAY FROM SALE END
        if (totalDaysFromEnd === 0 && hoursUntilEnd === 0 && minutesUntilEnd === 0) {
            return (
                <StyledLink
                    href={`/item/${itemId}`}
                    onClick={onNavigate}
                >
                    <RedTextButtonRight
                        className={cn('flex items-center', className)}
                        role="button"
                    >
                        <FormattedMessage
                            id="itemBidding.endsInTimeSeconds"
                            values={{ seconds: secondsUntilEnd }}
                        />
                    </RedTextButtonRight>
                </StyledLink>
            );
        }

        // LESS THAN ONE HOUR AWAY FROM SALE END
        if (totalDaysFromEnd === 0 && hoursUntilEnd === 0) {
            return (
                <StyledLink
                    href={`/item/${itemId}`}
                    onClick={onNavigate}
                >
                    <RedTextButtonRight
                        className={cn('flex items-center', className)}
                        role="button"
                    >
                        <FormattedMessage
                            id="itemBidding.endsInTimeMinutes"
                            values={{
                                minutes: minutesUntilEnd,
                                seconds: secondsUntilEnd,
                            }}
                        />
                    </RedTextButtonRight>
                </StyledLink>
            );
        }

        // ENDS IN MORE THAN ONE HOUR
        if (totalDaysFromEnd < 0) {
            return (
                <StyledLink
                    href={`/item/${itemId}`}
                    onClick={onNavigate}
                >
                    <RedTextButtonRight
                        className={cn('flex items-center', className)}
                        role="button"
                    >
                        <FormattedMessage
                            id="itemBidding.endsIn"
                            values={{
                                hours: hoursUntilEnd,
                                minutes: minutesUntilEnd,
                            }}
                        />
                    </RedTextButtonRight>
                </StyledLink>
            );
        }
    }

    return (
        <BodySecondary className={className}>
            <CatalogDate
                date={saleStartTs}
                showDateWithoutYear
                timedPlus
            />
        </BodySecondary>
    );
}

const StyledLink = styled(SubtleLink)`
    flex-shrink: 0;
    flex-grow: 1;
`;
