import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getIsRegistered,
    getRegistrationApproved,
} from '@/redux/modules/catalog/registration/catalogRegistration.selectors';
import {
    getIsTimedPlusBiddingOpen,
    getItemModelBidCount,
    getItemModelCatalogId,
    getItemModelIsPassed,
    getItemModelIsPaused,
    getItemModelIsSold,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { isSearchPage } from '@/utils/urls';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { openRegisterForAuctionModal } from '@/redux/modules/modal';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import ActionText from '@liveauctioneers/caterwaul-components/lib/ActionText/ActionText';
import Button from '@liveauctioneers/caterwaul-components/lib/Button/Button';
import ItemCardBidButton from '@/components/GridItemCard/Card/components/ItemCardBidButton/ItemCardLiveBidButton';
import ItemCardEstimate from '@/components/GridItemCard/Card/components/ItemCardEstimate/ItemCardEstimate';
import ItemCardMenu from '@/components/ItemCard/components/Menu/ItemCardMenu';
import ItemCardPriceRow from '@/components/GridItemCard/Card/components/ItemCardPriceRow/ItemCardPriceRow';
import ItemPriceGatewayContainer from '@/components/ItemPriceGateway/ItemPriceGatewayContainer';
import PriceRow from '@/components/ItemCard/PriceRow';
import styled, { useTheme } from 'styled-components';
import useIsWiderThan from '@/hooks/useIsWiderThan';

export type RegisterLinkOrPriceProps = {
    itemId: number;
    linkHover?: boolean;
    onNavigate?: () => void;
    onRegisterForAuctionClicked?: () => void;
};

const RegisterLinkOrPrice = ({
    itemId,
    linkHover,
    onNavigate,
    onRegisterForAuctionClicked,
}: RegisterLinkOrPriceProps) => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(isUserLoggedIn);
    const catalogId = useAppSelector((state) => getItemModelCatalogId(state, itemId));
    const bidCount = useAppSelector((state) => getItemModelBidCount(state, itemId));
    const isSold = useAppSelector((state) => getItemModelIsSold(state, itemId));
    const isPassed = useAppSelector((state) => getItemModelIsPassed(state, itemId));
    const isPaused = useAppSelector((state) => getItemModelIsPaused(state, itemId));
    const approved = useAppSelector((state) => getRegistrationApproved(state, catalogId));
    const registered = useAppSelector((state) => getIsRegistered(state, catalogId));
    const isLargeScreen = useIsWiderThan(theme.breakpoints.mobileWidthNum);

    const isHiddenMenuPage = pathname.includes('/catalog/') || pathname.includes('/item/');
    const isHiddenButtonPage = pathname.includes('/c/') || pathname.includes('/item/') || pathname.includes('/search/');

    const isTimedPlusBiddingOpen = useAppSelector((state) => getIsTimedPlusBiddingOpen(state, itemId));

    const isOnSearchPage = isSearchPage(pathname);

    let content: JSX.Element;

    if (isSold) {
        if (isLoggedIn) {
            content = (
                <StyledRow>
                    <ItemCardPriceRow
                        isHovered={linkHover}
                        itemId={itemId}
                        onNavigate={() => onNavigate?.()}
                        showBidCount={Boolean(bidCount)}
                    />
                    {!isHiddenMenuPage && !isOnSearchPage && <ItemCardMenu itemId={itemId} />}
                </StyledRow>
            );
        } else {
            content = (
                <StyledItemPriceGatewayContainer
                    hideIcon
                    itemId={itemId}
                />
            );
        }
    } else {
        const isOpenItem = !isPassed && !isPaused && isTimedPlusBiddingOpen;
        const isApprovedForOpenItem = isOpenItem && approved;
        const needsToRegister = !isPassed && !registered && !isHiddenMenuPage;
        let userActionButtonOrLink: JSX.Element;
        if (isLargeScreen) {
            if (isApprovedForOpenItem) {
                userActionButtonOrLink = <ItemCardBidButton itemId={itemId} />;
            } else if (needsToRegister) {
                userActionButtonOrLink = (
                    <StyledRegisterButton
                        onClick={() => {
                            onRegisterForAuctionClicked?.();
                            dispatch(
                                openRegisterForAuctionModal({
                                    catalogId,
                                    itemId,
                                })
                            );
                        }}
                        primary={!isOpenItem}
                        red={isOpenItem}
                        small
                    >
                        <FormattedMessage id="itemPage.biddingInfo.timedPlus.notStarted.registerForAuction" />
                    </StyledRegisterButton>
                );
            }
        } else {
            if (isApprovedForOpenItem) {
                userActionButtonOrLink = <ItemCardBidButton itemId={itemId} />;
            } else if (needsToRegister) {
                userActionButtonOrLink = (
                    <ActionText
                        onClick={() => {
                            dispatch(
                                openRegisterForAuctionModal({
                                    catalogId,
                                    itemId,
                                })
                            );
                        }}
                    >
                        <FormattedMessage id="itemPage.biddingInfo.timedPlus.notStarted.registerForAuction" />
                    </ActionText>
                );
            }
        }
        content = (
            <CatalogCTAContainer>
                <ItemCardEstimate itemId={itemId} />
                <StyledRow>
                    <StyledPriceRow
                        isCatalogCard
                        itemId={itemId}
                        onNavigate={() => onNavigate?.()}
                    />
                    {!isHiddenMenuPage && !isOnSearchPage && <ItemCardMenu itemId={itemId} />}
                </StyledRow>
                {!isHiddenButtonPage && !isOnSearchPage && <ButtonContainer>{userActionButtonOrLink}</ButtonContainer>}
            </CatalogCTAContainer>
        );
    }

    return <RegisterLinkOrPriceContainer>{content}</RegisterLinkOrPriceContainer>;
};

export default RegisterLinkOrPrice;

const RegisterLinkOrPriceContainer = styled.div`
    margin-top: 4px;
    position: relative;
    grid-area: registerlinkorprice;
    height: 100%;
`;

const StyledRow = styled.div`
    grid-area: price;
    display: flex;
    justify-content: space-between;
`;

const StyledPriceRow = styled(PriceRow)`
    margin-top: 0;
    margin-bottom: 10px;
`;

const StyledItemPriceGatewayContainer = styled(ItemPriceGatewayContainer)`
    font-size: 16px;
    button {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.blue200};
    }

    &:hover button {
        text-decoration: underline;
    }
`;

const CatalogCTAContainer = styled.div`
    display: flex;
    flex-flow: column;
`;

const ButtonContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: flex-end;
`;

const StyledRegisterButton = styled(Button)`
    width: 100%;
`;
