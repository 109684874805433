import { checkReadyToBid } from '@/components/GridItemCard/Card/helpers/checkReadyToBid';
import { CompleteAccountModalContext, ModalContext, openPlaceAbsenteeBidModal } from '@/redux/modules/modal';
import { EventContext } from '@/redux/modules/analytics';
import { fetchBidIncrementsIfNeeded } from '@/redux/modules/bidIncrements/bidIncrements.actions';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { fetchItemFacetsIfNeeded } from '@/redux/modules/itemFacets';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getBidderId, getUserIsInGoodStanding } from '@/redux/modules/account/user/user.selectors';
import { getBidderSubmittedBidId, submitLiveBid } from '@/redux/modules/liveBid';
import { getBidIncrementsByItemId } from '@/redux/modules/bidIncrements/bidIncrements.selectors';
import { getBidLimitBalance } from '@/redux/modules/bidLimit';
import { getCatalogIsInstantAutopay } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import {
    getCatalogNetworkStatus,
    getLatestMessageTimestamp,
    getLiveCatalogClosed,
    getLiveCatalogCurrentItemId,
    getLiveCatalogStatus,
    getLiveCatalogWon,
    getLiveItemDataCurrentAskPrice,
    getLiveItemDataLeadingBidder,
} from '@/redux/modules/console';
import { getCatalogRegistration } from '@/redux/modules/catalog/registration/catalogRegistration.selectors';
import { getIsTimedPlus, getItemSummary } from '@/redux/modules/item/summary/itemSummary.selectors';
import { getNextBidMinimum } from '@/utils/absenteeBids';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import { useTrackPlaceBidPressedAnalytics } from '@/utils/analytics';
import Button from '@liveauctioneers/caterwaul-components/lib/Button/Button';
import FormattedCurrency from '@/components/Formatted/FormattedCurrency';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export type ItemCardLiveBidButtonProps = {
    itemId: number;
};

const ItemCardLiveBidButton = ({ itemId }: ItemCardLiveBidButtonProps) => {
    const dispatch = useAppDispatch();

    const accountInGoodStanding = useAppSelector(getUserIsInGoodStanding);

    const {
        bidderHasBid,
        bidderMaxBid,
        catalogId,
        currency = 'USD',
        isTimedPlusAuction,
        leadingBid,
        salePrice,
        startPrice,
    } = useAppSelector((state) => getItemSummary(state, itemId));

    const { approved, bidLimit, blockedByAuctioneer, declined, limited, suspended } = useAppSelector((state) =>
        getCatalogRegistration(state, catalogId)
    );

    const bidIncrements = useAppSelector((state) => getBidIncrementsByItemId(state, itemId));

    const isTimedPlus = useAppSelector((state) => getIsTimedPlus(state, itemId));
    const closed = useAppSelector((state) => getLiveCatalogClosed(state, catalogId));
    const won = useAppSelector((state) => getLiveCatalogWon(state, catalogId));
    const status = useAppSelector((state) => getLiveCatalogStatus(state, catalogId));
    const currentItem = useAppSelector((state) => getLiveCatalogCurrentItemId(state, catalogId));
    const currentAskPrice = useAppSelector((state) => getLiveItemDataCurrentAskPrice(state, itemId));
    const liveUpdateTimestamp = useAppSelector((state) => getLatestMessageTimestamp(state, catalogId));
    const bidLimitBalance = useAppSelector((state) => getBidLimitBalance(state, catalogId));
    const currentPrice = leadingBid || salePrice || startPrice || 0;
    const overBidLimit = limited ? bidLimitBalance + currentPrice > bidLimit : false;
    const networkStatus = useAppSelector((state) => getCatalogNetworkStatus(state, catalogId));
    const bidderId = useAppSelector(getBidderId);
    const leadingBidderId = useAppSelector((state) => getLiveItemDataLeadingBidder(state, itemId));
    const isInstantAutopay = useAppSelector((state) => getCatalogIsInstantAutopay(state, catalogId));
    const location = useLocation();
    const trackPlaceBidButtonPressedAnalytics = useTrackPlaceBidPressedAnalytics(catalogId, itemId);

    const leadingBidder = leadingBidderId === bidderId;

    const [bidAmount, setBidAmount] = useState(
        getNextBidMinimum(bidIncrements, Math.max(bidderMaxBid, leadingBid), startPrice) || 0
    );

    const newBidMinimum = getNextBidMinimum(bidIncrements, Math.max(bidderMaxBid, leadingBid), startPrice) || 0;

    const submitted = useAppSelector(getBidderSubmittedBidId) === itemId;

    const isStoppedFromBidding =
        !accountInGoodStanding ||
        (!approved && !limited) ||
        suspended ||
        declined ||
        blockedByAuctioneer ||
        overBidLimit ||
        !checkReadyToBid({
            catalogId,
            closed,
            currentAskPrice,
            currentItem,
            itemId,
            liveUpdateTimestamp,
            networkStatus,
            status,
            won,
        });

    const [isReadyToTrack, setIsReadyToTrack] = useState(false);

    const handlePlaceBid = async () => {
        if (isTimedPlus) {
            await placeAbsenteeBid();
        } else {
            placeBid();
        }
        setIsReadyToTrack(true);
    };

    useEffect(() => {
        if (isReadyToTrack) {
            trackPlaceBidButtonPressedAnalytics();
            setIsReadyToTrack(false);
        }
    }, [isReadyToTrack, trackPlaceBidButtonPressedAnalytics]);

    const modalContext = React.useMemo<ModalContext>(
        () => ({
            eventContext: EventContext.placeBid,
            id: itemId,
            itemId,
            type: CompleteAccountModalContext.PlaceBid,
        }),
        [itemId]
    );

    const placeAbsenteeBid = async () => {
        const actionPromises: Promise<any>[] = [
            dispatch(fetchItemFacetsIfNeeded([itemId])),
            dispatch(fetchBidIncrementsIfNeeded(catalogId)),
            dispatch(fetchCatalogsIfNeeded({ catalogIds: [catalogId] })),
        ];
        await Promise.all(actionPromises);
        dispatch(
            openPlaceAbsenteeBidModal({
                bidAmount,
                isInstantAutopay,
                itemId,
                modalContext,
            })
        );
    };

    const placeBid = () => {
        // don't only rely on the "disabled" button to prevent bidders who haven't been approved
        if ((approved || limited) && !isStoppedFromBidding) {
            dispatch(submitLiveBid(Date.now(), catalogId, currentAskPrice, currency, itemId, location));
        } else {
            return;
        }
    };

    useEffect(() => {
        setBidAmount(newBidMinimum);
    }, [bidIncrements, newBidMinimum]);

    if (leadingBidder && !isTimedPlusAuction) {
        return (
            <StyledWinningButton
                primary
                small
            >
                <FormattedMessage id="bidding_console.console.button.winning" />
            </StyledWinningButton>
        );
    }

    const timedPlusTextId = bidderHasBid ? 'increase_bid' : 'catalogPage.placeBid';

    return (
        <StyledButton
            disabled={submitted}
            loading={submitted}
            onClick={handlePlaceBid}
            primary
            red
            small
        >
            <FormattedMessage
                id={isTimedPlus ? timedPlusTextId : 'bidding_console.console.button.bid'}
                values={{
                    amount: (
                        <StyledCurrency
                            noFraction
                            sourceCurrency={currency}
                            value={currentAskPrice | bidAmount}
                        />
                    ),
                }}
            />
        </StyledButton>
    );
};

export default ItemCardLiveBidButton;

const StyledCurrency = styled(FormattedCurrency)`
    margin-left: 5px;
`;

const StyledButton = styled(Button)`
    width: 100%;
    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        width: 211px;
    }
`;

const StyledWinningButton = styled(Button)`
    background-color: ${({ theme }) => theme.colors.teal};
    border-color: ${({ theme }) => theme.colors.teal};
    cursor: default;
    width: 100%;
`;
