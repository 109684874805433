import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { getItemModelIsPassed, getItemModelIsSold } from '@/redux/modules/item/summary/itemSummary.selectors';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useEffect, useState } from 'react';

type Props = {
    itemId: number;
    saleEnd: number;
    today: number;
};
const fifteenSeconds = 15_000; // 15 seconds in ms.

export const useRefetchItemSummariesIfNeeded = ({ itemId, saleEnd, today }: Props) => {
    const dispatch = useAppDispatch();
    const isSold = useAppSelector((state) => getItemModelIsSold(state, itemId));
    const isPassed = useAppSelector((state) => getItemModelIsPassed(state, itemId));

    const [hasRefetchedItems, setHasRefetchedItems] = useState(false);

    useEffect(() => {
        if (saleEnd - today < fifteenSeconds && saleEnd - today > 0 && !hasRefetchedItems && !isSold && !isPassed) {
            // Refetch item once timer is below 15s
            dispatch(
                fetchItemSummariesIfNeeded({
                    force: true,
                    identifier: 'TIMED-PLUS-ITEM-CARD-TIMER',
                    itemIds: [itemId],
                })
            );
            setHasRefetchedItems(true);
        } else if (saleEnd - today > fifteenSeconds && hasRefetchedItems) {
            // Reset the should fetch if sale end gets extended
            setHasRefetchedItems(false);
        }
    }, [today, saleEnd, hasRefetchedItems, setHasRefetchedItems, dispatch, itemId, isSold, isPassed]);
};
